import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_UYiXMU8ZyN from "/usr/src/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import switch_locale_path_ssr_68p07uUINE from "/usr/src/app/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_wXHWvfXlb5 from "/usr/src/app/node_modules/@nuxtjs/i18n-edge/dist/runtime/plugins/i18n.js";
import plugin_AUP22rrBAc from "/usr/src/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import _0_auth_6wDZup34ty from "/usr/src/app/auth/scbdIframe/plugins/0.auth.ts";
import app_hooks_BPbKeUkULA from "/usr/src/app/plugins/app-hooks.ts";
import app_routes_WWYZOeklnc from "/usr/src/app/plugins/app-routes.ts";
import coreui_iGbbbpkyAa from "/usr/src/app/plugins/coreui.ts";
import custom_routes_tjypUb4bFb from "/usr/src/app/plugins/custom-routes.ts";
import error_handler_kEP5FliEXj from "/usr/src/app/plugins/error-handler.ts";
import external_libs_3gyStlGgHE from "/usr/src/app/plugins/external-libs.ts";
import fetchConf_tia6LceQiR from "/usr/src/app/plugins/fetchConf.ts";
import fontawesome_cn2c4tOOHz from "/usr/src/app/plugins/fontawesome.ts";
import jquery_dwZrqeP0xs from "/usr/src/app/plugins/jquery.ts";
import reCaptcha_wv3yBvofF8 from "/usr/src/app/plugins/reCaptcha.ts";
import register_apis_NrQPs7RdqG from "/usr/src/app/plugins/register-apis.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_UYiXMU8ZyN,
  switch_locale_path_ssr_68p07uUINE,
  i18n_wXHWvfXlb5,
  plugin_AUP22rrBAc,
  _0_auth_6wDZup34ty,
  app_hooks_BPbKeUkULA,
  app_routes_WWYZOeklnc,
  coreui_iGbbbpkyAa,
  custom_routes_tjypUb4bFb,
  error_handler_kEP5FliEXj,
  external_libs_3gyStlGgHE,
  fetchConf_tia6LceQiR,
  fontawesome_cn2c4tOOHz,
  jquery_dwZrqeP0xs,
  reCaptcha_wv3yBvofF8,
  register_apis_NrQPs7RdqG
]